import React from 'react';
import { palette } from '@aleohq/ui/dist/palette';
import AleoThemeContext from './AleoThemeContext';

export type AleoTheme = 'orange' | 'blue' | 'lightBlue' | 'lightPurple' | 'green' | 'teal';

interface AleoThemeProviderProps {
    theme: AleoTheme;
    children: React.ReactNode;
}

const AleoThemeProvider = (props: AleoThemeProviderProps) => {
    const { theme, children } = props;

    return (
        <AleoThemeContext.Provider value={{ primaryColor: palette[`${theme}6`] }}>{children}</AleoThemeContext.Provider>
    );
};

export default AleoThemeProvider;
