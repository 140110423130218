import React from 'react';
import styled from 'styled-components';
import { palette } from '@aleohq/ui/dist/palette';
import { Space } from 'antd';
import { DiscordIcon, GitHubIcon, TwitterIcon } from '../Icons';
import LinkList, { Link } from './LinkList';

interface LargeFooterProps {
    logo: string;
    links: Link[];
    linkRender: (link: Link) => React.ReactNode;
}

const StyledFooter = styled.div`
    min-height: 460px;
    width: 100%;
    background-color: ${palette.gray3};
    border-radius: 20px;
    padding: 60px;
    display: flex;
    flex-direction: column;
    margin-bottom: 75px;

    @media (max-width: 800px) {
        padding: 30px;
    }
`;

const ContentWrapper = styled.div`
    flex: 1;
    display: flex;
    justify-content: space-between;

    @media (max-width: 800px) {
        flex-direction: column;
    }
`;

const LeftHalf = styled.div`
    display: flex;
    flex-direction: column;
`;

const RightHalf = styled.div`
    display: flex;
    gap: 50px;

    @media (max-width: 800px) {
        flex-direction: column;
        gap: 0;
        margin-top: 20px;
    }
`;

const LogoWrapper = styled.div``;

const Logo = styled.img`
    height: 45px;
    width: auto;

    @media (max-width: 800px) {
        height: 30px;
    }
`;

const Slogan = styled.p`
    font-family: Inter;
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 28px;
    color: ${palette.gray9};
    margin: 20px 0;
`;

const Bottom = styled.div`
    display: flex;
    justify-content: space-between;

    @media (max-width: 800px) {
        flex-direction: column-reverse;

        & > *:last-child {
            margin-bottom: 25px;
        }
    }
`;

const FooterText = styled.p`
    font-family: Inter;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
    margin-bottom: 0;
`;

const Copyright = styled(FooterText)`
    color: #b2b6c7;
`;

const MadeText = styled(FooterText)`
    color: #ffffff;
`;

const LargeFooter = (props: LargeFooterProps) => {
    const forDeveloperLinks: Link[] = [
        { url: '', label: 'For Developers' },
        { url: 'https://www.aleo.studio/', label: 'Aleo Studio' },
        { url: 'https://aleo.network/', label: 'Aleo Explorer' },
        { url: 'https://aleo.pm/', label: 'Aleo Package Manager' },
        { url: 'https://developer.aleo.org/', label: 'Aleo Developer Docs' }
    ];

    return (
        <StyledFooter>
            <LogoWrapper>
                <Logo src={props.logo} />
            </LogoWrapper>

            <ContentWrapper>
                <LeftHalf>
                    <Slogan>
                        Where Applications
                        <br />
                        Become Private.
                    </Slogan>

                    <Space size={15}>
                        <a href="https://twitter.com/aleohq" target="_blank" rel="noreferrer">
                            <TwitterIcon style={{ color: 'white' }} />
                        </a>
                        <a href="https://github.com/AleoHQ" target="_blank" rel="noreferrer">
                            <GitHubIcon style={{ color: 'white' }} />
                        </a>
                        <a href="https://discord.com/invite/SMXsDEQ" target="_blank" rel="noreferrer">
                            <DiscordIcon style={{ color: 'white' }} />
                        </a>
                    </Space>
                </LeftHalf>

                <RightHalf>
                    <LinkList links={props.links} render={props.linkRender} />
                    <LinkList links={forDeveloperLinks} render={(link) => <a href={link.url}>{link.label}</a>} />
                </RightHalf>
            </ContentWrapper>

            <Bottom>
                <Copyright>
                    © Aleo Systems Inc • Your privacy is{' '}
                    <a href="https://aleo.org/privacy" target="_blank" rel="noreferrer" style={{ color: 'white' }}>
                        Protected
                    </a>
                    .
                </Copyright>

                <MadeText>
                    Made with <span style={{ color: '#e41818' }}>❤️</span>️ in San Francisco
                </MadeText>
            </Bottom>
        </StyledFooter>
    );
};

export default LargeFooter;
