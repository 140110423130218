import styled from 'styled-components';

const StyledBanner = styled.div<{ background: string }>`
    background: ${(props) => props.background};
    height: 60px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
`;

export default StyledBanner;
