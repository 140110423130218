import React from 'react';
import styled from 'styled-components';
import { palette } from '@aleohq/ui/dist/palette';

export interface Link {
    url: string;
    label: string;
}

interface LinkListProps {
    links: Link[];
    render: (link: Link) => React.ReactNode;
}

const LinkListWrapper = styled.ul`
    list-style-type: none;
    padding: 15px;

    li {
        margin-bottom: 15px;

        a {
            color: ${palette.gray9};
            font-family: Inter;
            font-style: normal;
            font-size: 14px;
            line-height: 17px;

            &:hover {
                color: #ffffff;
            }
        }

        &:first-child {
            a {
                color: #ffffff;
                font-weight: 600;
            }
        }
    }

    @media (max-width: 800px) {
        padding: 20px 0 0 0;
    }
`;

const LinkList = (props: LinkListProps) => (
    <LinkListWrapper>
        {props.links.map((link) => (
            <li key={link.label}>{props.render(link)}</li>
        ))}
    </LinkListWrapper>
);

export default LinkList;
