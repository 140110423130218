import React, { CSSProperties, useContext } from 'react';
import styled, { css, StyledComponent } from 'styled-components';
import { palette } from '@aleohq/ui/dist/palette';
import { isMobile } from 'react-device-detect';
import AleoThemeContext from '../Theme/AleoThemeContext';

type Style =
    | 'title'
    | 'heading-1'
    | 'heading-2'
    | 'heading-3'
    | 'heading-4'
    | 'subtitle'
    | 'body'
    | 'body-small'
    | 'footnote';
type Weight = 'normal' | 'semibold';
type Type = 'default' | 'primary' | 'secondary';

export interface AleoTypographyProps {
    textStyle: Style;
    mobileTextStyle?: Style;
    weight?: Weight;
    type?: Type;
    style?: CSSProperties;
    noMargin?: boolean;
    className?: string;
    children: React.ReactNode;
}

interface TypographyProps {
    fontWeight: number;
    color?: string;
    noMargin?: boolean;
}

const commonStyles = css`
    font-family: Inter, Helvetica, sans-serif;
    font-style: normal;
    font-weight: ${(props: TypographyProps) => props.fontWeight};
    color: ${(props: TypographyProps) => props.color};
    margin: ${(props: TypographyProps) => (props.noMargin ? 0 : '0 0 0.5em 0')};
`;

const Title = styled.h1<TypographyProps>`
    font-size: 56px;
    line-height: 64px;
    ${commonStyles}
`;

const Heading1 = styled.h1<TypographyProps>`
    font-size: 46px;
    line-height: 54px;
    ${commonStyles}
`;

const Heading2 = styled.h2<TypographyProps>`
    font-size: 38px;
    line-height: 46px;
    ${commonStyles}
`;

const Heading3 = styled.h3<TypographyProps>`
    font-size: 30px;
    line-height: 38px;
    ${commonStyles}
`;

const Heading4 = styled.h4<TypographyProps>`
    font-size: 24px;
    line-height: 32px;
    ${commonStyles}
`;

const Subtitle = styled.h5<TypographyProps>`
    font-size: 20px;
    line-height: 28px;
    ${commonStyles}
`;

const Body = styled.p<TypographyProps>`
    font-size: 16px;
    line-height: 24px;
    ${commonStyles}
`;

const BodySmall = styled.p<TypographyProps>`
    font-size: 14px;
    line-height: 22px;
    ${commonStyles}
`;

const Footnote = styled.p<TypographyProps>`
    font-size: 12px;
    line-height: 20px;
    ${commonStyles}
`;

const AleoTypography = (props: AleoTypographyProps) => {
    const { textStyle, mobileTextStyle, children, ...rest } = props;
    const theme = useContext(AleoThemeContext);

    const weightFunc = (props: { weight?: Weight }): number => (props.weight === 'semibold' ? 600 : 400);
    const colorFunc = (props: { type?: Type }) => {
        switch (props.type) {
            case 'primary':
                return theme.primaryColor;
            case 'secondary':
                return palette.gray9;
            default:
                return '#ffffff';
        }
    };

    const ComponentMap: { [key: string]: StyledComponent<any, any, TypographyProps> } = {
        title: Title,
        'heading-1': Heading1,
        'heading-2': Heading2,
        'heading-3': Heading3,
        'heading-4': Heading4,
        subtitle: Subtitle,
        body: Body,
        'body-small': BodySmall,
        footnote: Footnote
    };

    const styleToUse: Style = isMobile ? props.mobileTextStyle ?? props.textStyle : props.textStyle;

    if (ComponentMap[styleToUse]) {
        const Component = ComponentMap[styleToUse];

        return (
            <Component fontWeight={weightFunc(props)} color={colorFunc(props)} {...rest}>
                {children}
            </Component>
        );
    } else {
        throw new Error('Invalid text style provided!');
    }
};

export default AleoTypography;
