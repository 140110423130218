import React from 'react';
import styled from 'styled-components';
import { Modal, ModalProps, Typography } from 'antd';
import { palette } from '@aleohq/ui/dist/palette';

const StyledModal = styled(Modal)`
    &.ant-modal .ant-modal-body,
    &.ant-modal .ant-modal-content .ant-modal-body {
        padding: 0;
        min-height: 710px;
        height: 100%;
    }
`;

const ModalRow = styled.div`
    display: flex;
    height: 100%;
    flex: 1;
`;

const FormWrapper = styled.div`
    width: 60%;
    padding: 55px 65px;
    display: flex;
    flex-direction: column;

    @media (max-width: 800px) {
        padding: 30px;
        width: 100%;
    }
`;

const GraphicWrapper = styled.div<{ background: string }>`
    flex: 1;
    padding: 60px 35px 0 65px;
    background: ${palette.gray1};
    background-image: url(${(props) => props.background});
    background-size: contain;
    background-repeat: no-repeat;
    background-position-y: 120px;
    height: 710px;
    border-top-left-radius: 20px;
    border-bottom-left-radius: 20px;

    @media (max-width: 800px) {
        display: none;
    }
`;

const WelcomeText = styled(Typography.Paragraph)`
    &.ant-typography {
        font-size: 24px;
        color: #ffffff;
        font-weight: normal;
        margin-bottom: 0;
    }
`;

interface AuthModalProps {
    children: React.ReactNode;
    graphicTitle: string;
    graphicSubtitle: string;
    background: string;
}

const AuthModal = (props: ModalProps & AuthModalProps) => {
    const { children, graphicTitle, graphicSubtitle, background, ...rest } = props;

    return (
        <StyledModal width={1000} {...rest}>
            <ModalRow>
                <GraphicWrapper background={background}>
                    <WelcomeText strong>{graphicTitle}</WelcomeText>
                    <WelcomeText>{graphicSubtitle}</WelcomeText>
                </GraphicWrapper>

                <FormWrapper>{props.children}</FormWrapper>
            </ModalRow>
        </StyledModal>
    );
};

export default AuthModal;
