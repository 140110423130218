import { Layout } from 'antd';
import React from 'react';
import styled from 'styled-components';
import { palette } from '@aleohq/ui/dist/palette';

interface HeaderProps {
    logo: string;
    logoRender?: (Logo: React.ReactNode) => React.ReactNode;
    children: React.ReactNode;
    banner?: React.ReactNode;
}

const Logo = styled.img`
    height: 30px;
    width: auto;
    margin-right: 40px;

    @media (max-width: 800px) {
        margin-right: 10px;
    }
`;

const HeaderWrapper = styled(Layout.Header)`
    font-family: Inter;
    font-size: 14px;
    line-height: 17px;
    color: ${palette.gray9};
    flex-direction: column;
    align-items: flex-start;
    height: unset;

    a {
        color: ${palette.gray9};

        &:hover {
            color: #ffffff;
        }
    }
`;

const StyledHeader = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    height: 93px;
`;

const HeaderContent = styled.div`
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: space-between;
`;

const BannerWrapper = styled.div`
    position: absolute;
    width: 100%;
    margin-top: 93px;
    left: 0;
`;

const Header = (props: HeaderProps) => {
    return (
        <HeaderWrapper>
            <StyledHeader>
                {props.logoRender ? props.logoRender(<Logo src={props.logo} />) : <Logo src={props.logo} />}

                <HeaderContent>{props.children}</HeaderContent>
            </StyledHeader>

            {props.banner && <BannerWrapper>{props.banner}</BannerWrapper>}
        </HeaderWrapper>
    );
};

export default Header;
