import styled from 'styled-components';

const MaskText = styled.span<{ mask: string }>`
    display: block;

    * {
        display: inline-block;
        background: ${(props) => props.mask};
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
    }
`;

export default MaskText;
